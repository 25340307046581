@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-black: #000;
  --color-purple: #371B79;
  --color-l-purple:#CAB1EF;
  --color-white: #fff;
  --color-off-white:#f2f2f2;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
/* body{
  background: #CAB1EF;
} */
